import fadeIn from 'plugins/animations/fade_in';
import isElement from 'plugins/element/is_element';
import isVisible from 'plugins/element/is_visible';
import addListener from 'plugins/utilities/add_listener';
import fire from 'plugins/utilities/fire';
import { trapFocus } from 'plugins/utilities/focus_trap';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import removeListener from 'plugins/utilities/remove_listener';
import setBodyOverflowHidden from 'plugins/utilities/set_body_overflow_hidden';

function beforeShowEventDetailFromOptions(options) {
  return Object.keys(options).reduce((acc, k) => {
    if (['forceOpen'].indexOf(k) > -1) {
      return acc;
    }

    return {
      ...acc,
      [k]: options[k],
    };
  }, {});
}

function findModal(nameOrModalElement) {
  if (isElement(nameOrModalElement)) return nameOrModalElement;

  return document.querySelector(
    // eslint-disable-next-line max-len
    `.modal[data-modal-name='${nameOrModalElement}'], section[data-modal-name='${nameOrModalElement}']`,
  );
}

function openModal(nameOrModalElement, options = {}) {
  const modalElement = findModal(nameOrModalElement);

  if (!isVariableDefinedNotNull(modalElement)) {
    console.warn(`Modal ${nameOrModalElement} not found.`);
    return false;
  }

  if (!options.forceOpen && isVisible(modalElement)) {
    console.warn(`Modal ${nameOrModalElement} is already visible.`);
    return false;
  }

  if (!isVariableDefinedNotNull(options.url)) {
    options.url = modalElement.dataset.modalUrl;
  }

  if (!isVariableDefinedNotNull(options.urlParams)) {
    options.urlParams = modalElement.dataset.modalUrlParams;
  }

  setBodyOverflowHidden('scrollLockModalCnt');

  const eventNamePrefix = modalElement.getAttribute('data-modal-event-name-prefix') || 'modal';

  fire(modalElement, `${eventNamePrefix}:beforeShow`, {
    detail: beforeShowEventDetailFromOptions(options),
  });

  const animationBegin = () => {
    trapFocus(modalElement);
    fire(modalElement, `${eventNamePrefix}:show`);
  };

  const animationComplete = () => {
    fire(modalElement, `${eventNamePrefix}:afterShow`);
  };

  if (modalElement.dataset.controller.includes('fuse--modal')) {
    const transitionEndListenerId = addListener(modalElement, 'transitionend', () => {
      animationComplete();
      removeListener(modalElement, { id: transitionEndListenerId });
    });

    modalElement.hidden = false;

    animationBegin();

    return true;
  }

  fadeIn(modalElement, {
    begin: () => animationBegin(),
    complete: () => animationComplete(),
  });

  return true;
}

export default openModal;
