import fadeOut from 'plugins/animations/fade_out';
import isElement from 'plugins/element/is_element';
import isVisible from 'plugins/element/is_visible';
import addListener from 'plugins/utilities/add_listener';
import fire from 'plugins/utilities/fire';
import { releaseFocus } from 'plugins/utilities/focus_trap';
import removeBodyOverflowHidden from 'plugins/utilities/remove_body_overflow_hidden';
import removeListener from 'plugins/utilities/remove_listener';

const SPECIFIC_MODAL_SELECTOR = (name) => `.modal[data-modal-name='${name}'], section[data-modal-name='${name}']`;
const VISIBLE_MODALS_SELECTOR =
  // eslint-disable-next-line max-len
  '.modal[style*="display: block"], [data-component-controller="general--modal2"][style*="display: block"], [data-controller~="fuse--modal"]:not([hidden])';

function hideModal(nameOrModalElement, { instant = false } = {}) {
  let modalElement;

  if (isElement(nameOrModalElement)) {
    modalElement = nameOrModalElement;
  } else {
    modalElement =
      typeof nameOrModalElement === 'undefined'
        ? document.querySelectorAll(VISIBLE_MODALS_SELECTOR)
        : document.querySelector(SPECIFIC_MODAL_SELECTOR(nameOrModalElement));
  }

  if (typeof nameOrModalElement === 'undefined') {
    if (!modalElement.length) {
      return false;
    }

    modalElement = [...modalElement].reverse().sort((a, b) => {
      const zIndexA = parseInt(window.getComputedStyle(a).zIndex, 10);
      const zIndexB = parseInt(window.getComputedStyle(b).zIndex, 10);
      return zIndexA - zIndexB;
    })[0];
  }

  if (!modalElement || !isVisible(modalElement)) {
    return false;
  }

  const eventNamePrefix = modalElement.getAttribute('data-modal-event-name-prefix') || 'modal';

  if (!fire(modalElement, `${eventNamePrefix}:beforeHide`)) {
    return false;
  }

  const animationBegin = () => {
    fire(modalElement, `${eventNamePrefix}:hide`);
  };

  const animationComplete = () => {
    const visibleModals = document.querySelectorAll(VISIBLE_MODALS_SELECTOR).length;

    fire(modalElement, `${eventNamePrefix}:afterHide`);
    releaseFocus(modalElement);
    removeBodyOverflowHidden('scrollLockModalCnt', visibleModals);
  };

  if (modalElement.dataset.controller.includes('fuse--modal')) {
    if (!instant) {
      const transitionEndListenerId = addListener(modalElement, 'transitionend', () => {
        animationComplete();
        removeListener(modalElement, { id: transitionEndListenerId });
      });
    }

    modalElement.hidden = true;

    animationBegin();

    if (instant) {
      animationComplete();
    }

    return true;
  }

  if (instant) {
    animationBegin();
    modalElement.style.display = 'none';
    animationComplete();

    return true;
  }

  fadeOut(modalElement, {
    begin: () => animationBegin(),
    complete: () => animationComplete(),
  });

  return true;
}

export default hideModal;
